/* eslint-disable no-param-reassign */
import * as companyRequests from '../../services/company-requests';

// keys for actiontypes
const ActionTypes = {
  GET_ALL_COMPANIES: 'GET_ALL_COMPANIES',
  GET_FRIDGES_FOR_PRIVATE_COMPANY: 'GET_FRIDGES_FOR_PRIVATE_COMPANY',
};

const getAllPrivateCompanies = () => {
  return async (dispatch) => {
    const data = await companyRequests.getAllPrivateCompanies();
    dispatch({ type: ActionTypes.GET_ALL_COMPANIES, payload: data });
  };
};

const getPrivateCompany = (id) => {
  return async (dispatch) => {
    await companyRequests.getPrivateCompany(id);
    const data = await companyRequests.getAllPrivateCompanies();
    dispatch({ type: ActionTypes.GET_ALL_COMPANIES, payload: data });
  };
};

const createPrivateCompany = (fields) => {
  return async (dispatch) => {
    await companyRequests.createPrivateCompany(fields);
    const data = await companyRequests.getAllPrivateCompanies();
    dispatch({ type: ActionTypes.GET_ALL_COMPANIES, payload: data });
  };
};

const updatePrivateCompany = (fields) => {
  return async (dispatch) => {
    await companyRequests.updatePrivateCompany(fields);
    const data = await companyRequests.getAllPrivateCompanies();
    dispatch({ type: ActionTypes.GET_ALL_COMPANIES, payload: data });
  };
};

const deletePrivateCompany = (id) => {
  return async (dispatch) => {
    await companyRequests.deletePrivateCompany(id);
    const data = await companyRequests.getAllPrivateCompanies();
    dispatch({ type: ActionTypes.GET_ALL_COMPANIES, payload: data });
  };
};

const getFridgesForPrivateCompany = (id) => {
  return async (dispatch) => {
    const data = await companyRequests.getFridgesForPrivateCompany(id);
    dispatch({ type: ActionTypes.GET_FRIDGES_FOR_PRIVATE_COMPANY, payload: { id, response: data } });
  };
};

export {
  ActionTypes,
  getAllPrivateCompanies,
  getPrivateCompany,
  createPrivateCompany,
  updatePrivateCompany,
  deletePrivateCompany,
  getFridgesForPrivateCompany,
};
